<template>
  <div>
    <b-card>
      <AgGrid
        ref="ESGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      >
        <template v-slot:lftbtn>
          <b-button
            v-b-tooltip.hover="'Create a New entry'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="CreateNewES()"
          ><font-awesome-icon
             :icon="['far', 'plus-square']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">New Entry</span></b-button>
        </template>

      </AgGrid>
    </b-card>

    <b-modal
      id="CreateEntryModal"
      ref="CreateEntry"
      title="New Entry"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="headerRules"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Customer"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Customer:</b></i></label>
                  <v-select
                    id="CustomerOption"
                    v-model="header.CustomerId"
                    :options="CustomerOptions"
                    label="name"
                    placeholder="Select a Customer"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Entry Type"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Entry Type:</b></i></label>
                  <v-select
                    id="EntryTypeOption"
                    v-model="header.EntryType"
                    :options="EntryTypeOptions"
                    label="text"
                    placeholder="Select the entry type"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Shipment Type"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Shipment Type:</b></i></label>
                  <v-select
                    id="ShipmentTypeOption"
                    v-model="header.ShipmentType"
                    :options="ShipmentTypeOptions"
                    label="text"
                    placeholder="Select the shipment type"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="MOT"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>MOT:</b></i></label>
                  <v-select
                    id="MOTOption"
                    v-model="header.MOTType"
                    :options="MOTOptions"
                    label="text"
                    placeholder="Select the MOT"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Reference #"
                rules="max:25"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Reference #:</b></i></label>
                  <b-form-input
                    id="ReferenceNumber"
                    v-model="header.ReferenceNumber"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Reference Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Broker Reference"
                rules="max:15"
              >
                <label><i><b>Broker Reference:</b></i></label>
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <b-form-input
                    id="BrokerReference"
                    v-model="header.BrokerReference"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Broker Reference"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Goods Description"
                rules="required|max:40"
              >
                <label class="required"><i><b>Goods Description:</b></i></label>
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <b-form-input
                    id="Description"
                    v-model="header.Description"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Goods Description"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Country of Origin"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Country of Origin:</b></i></label>
                  <v-select
                    id="COO"
                    v-model="header.COO"
                    :options="CountryOptions"
                    label="country"
                    placeholder="Select the COO"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Export Country"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Export Country:</b></i></label>
                  <v-select
                    id="ExportCountry"
                    v-model="header.ExportCountry"
                    :options="CountryOptions"
                    label="country"
                    placeholder="Select the export country"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="SaveNewEntry()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from '@axios'

import { required } from '@validations'
import ViewBtnRenderer from '@core/layouts/ViewBtnRenderers/ABI/ABIESViewBtnRenderer'
import AgGrid from '@/layouts/components/AgGrid.vue'

export default {
  components: {
    AgGrid,
  },
  data() {
    return {
      required,
      aggrid: [],
      esId: '',
      CustomerOptions: [],
      CountryOptions: [],
      EntryActionOption: [
        { text: 'Action: Add', value: 'A' },
        { text: 'Action: Replace', value: 'R' },

      ],
      EntryTypeOptions: [
        { text: 'Free and Dutiable (01)', value: '01' },
        { text: 'Quota/Visa (02)', value: '02' },
        { text: 'AD/CVD (03)', value: '03' },
        { text: 'Appraisement (04)', value: '04' },
        { text: 'Vessel Repair (05)', value: '05' },
        { text: 'FTZ Consumption (06)', value: '06' },
        { text: 'Quota/Visa & AD/CVD (07)', value: '07' },
        { text: 'Duty Deferrals (08)', value: '08' },
        { text: 'Free and Dutiable (11)', value: '11' },
        { text: 'Quota Other Than Textiles (12)', value: '12' },
        { text: 'Warehouse (21)', value: '21' },
        { text: 'Re-Warehouse (22)', value: '22' },
        { text: 'TIB (23)', value: '23' },
        { text: 'Trade Fair (24)', value: '24' },
        { text: 'Permanent Exhibition (25)', value: '25' },
        { text: 'FTZ Admission (26)', value: '26' },
        { text: 'Consumption (31)', value: '31' },
        { text: 'Quota/Visa (32)', value: '32' },
        { text: 'AD/CVD (34)', value: '34' },
        { text: 'Quota/Visa & AD/CVD (38)', value: '38' },
        { text: 'DCMAO (51)', value: '51' },
        { text: 'Other Agency (52)', value: '52' },
        { text: 'I.T. (61)', value: '61' },
        { text: 'Transportation & Exportation (62)', value: '62' },
        { text: 'I.E. (63)', value: '63' },
      ],
      MOTOptions: [
        { text: 'Vessel, Non-container (10)', value: '10' },
        { text: 'Vessel, Container (11)', value: '11' },
        { text: 'Border, Waterborne (12)', value: '12' },
        { text: 'Rail, Non-container (20)', value: '20' },
        { text: 'Rail, Container (21)', value: '21' },
        { text: 'Truck, Non-Container (30)', value: '30' },
        { text: 'Truck, Container (31)', value: '31' },
        { text: 'Auto (32)', value: '32' },
        { text: 'Pedestrain (33)', value: '33' },
        { text: 'Road, other (34)', value: '34' },
        { text: 'Air, Non-container (40)', value: '40' },
        { text: 'Air, Container (41)', value: '41' },
        { text: 'Mail (50)', value: '50' },
        { text: 'Passenger, hand-carried (60)', value: '60' },
        { text: 'Fiex Transport (70)', value: '70' },
      ],
      ShipmentTypeOptions: [
        { text: 'Personal', value: '0' },
        { text: 'Commercial', value: '2' },
        { text: 'Sample', value: '1' },

      ],
      header: {
        CustomerId: '',
        EntryAction: null,
        EntryType: null,
        MOTType: null,
        ShipmentType: null,
        ReferenceNumber: '',
        BrokerReference: '',
        Description: '',
        COO: null,
        ExportCountry: null,

      },

    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.aggrid = this.InitGridInstance('ES')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: 'esId',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 60,
        minWidth: 60,
        maxWidth: 60,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'ViewBtnRenderer',
        enableRowGroup: false,
        enablePivot: false,
      },
      { headerName: 'Active', field: 'isActive', minWidth: 100 },
      { headerName: 'Status', field: 'status', minWidth: 100 },
      { headerName: 'In ACE', field: 'inACE', minWidth: 100 },
      {
        headerName: 'Entry Detail',
        marryChildren: true,
        children: [
          {
            headerName: 'Revision', columnGroupShow: 'open', field: 'revision', minWidth: 110, filter: false, enableRowGroup: false, enablePivot: false,
          },
          {
            headerName: 'Filer', field: 'entryFiler', minWidth: 90, filter: false,
          },
          {
            headerName: 'Entry #', field: 'entryNumberWithCheckdigit', minWidth: 125, enableRowGroup: false, enablePivot: false,
          },
          {
            headerName: 'Ref #', field: 'referenceNumber', minWidth: 175, enableRowGroup: false, enablePivot: false,
          },
          {
            headerName: 'ET', field: 'entryType', minWidth: 75, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'MOT', field: 'mot', minWidth: 90, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Bond', columnGroupShow: 'open', field: 'bondType', minWidth: 87, filter: 'agSetColumnFilter',
          },
        ],
      },
      {
        headerName: 'Customer',
        marryChildren: true,
        children: [
          { headerName: 'Customer', field: 'customer', minWidth: 255 },
          {
            headerName: 'Importer', columnGroupShow: 'open', field: 'importer', minWidth: 255,
          },
          {
            headerName: 'Consignee', columnGroupShow: 'open', field: 'consignee', minWidth: 255,
          },
        ],
      },
      {
        headerName: 'Location',
        marryChildren: true,
        children: [
          {
            headerName: 'COO', columnGroupShow: 'open', field: 'coo', minWidth: 85, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Export Country', columnGroupShow: 'open', field: 'exportCountry', minWidth: 95, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Destination', field: 'destination', minWidth: 130, filter: 'agSetColumnFilter',
          },
        ],
      },
      {
        headerName: 'Transportation',
        marryChildren: true,
        children: [
          {
            headerName: 'POX', field: 'pox', maxWidth: 82, minWidth: 82, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'POE', field: 'poe', maxWidth: 82, minWidth: 82, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'POL', columnGroupShow: 'open', field: 'pol', maxWidth: 82, minWidth: 82, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'POA', columnGroupShow: 'open', field: 'poa', maxWidth: 82, minWidth: 82, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'POU', field: 'pou', maxWidth: 82, minWidth: 82, filter: 'agSetColumnFilter',
          },
          { headerName: 'Carrier', field: 'carrierCode', width: 105 },
          {
            headerName: 'Flight/Voyage', columnGroupShow: 'open', field: 'flightVoyage', maxWidth: 200, minWidth: 200, enableRowGroup: false, enablePivot: false,
          },
        ],
      },
      {
        headerName: 'Dates',
        marryChildren: true,
        children: [
          {
            headerName: 'Summary', columnGroupShow: 'open', field: 'summaryDate', maxWidth: 126, minWidth: 126,
          },
          {
            headerName: 'Export', field: 'exportDate', maxWidth: 126, minWidth: 126, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Import', field: 'importDate', maxWidth: 126, minWidth: 126, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Entry', field: 'entryDate', maxWidth: 126, minWidth: 126, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Arrival', columnGroupShow: 'open', field: 'arrivalDate', maxWidth: 126, minWidth: 126, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Tariff', columnGroupShow: 'open', field: 'tariffDate', maxWidth: 126, minWidth: 126, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Release', field: 'releaseDate', maxWidth: 126, minWidth: 126,
          },
          {
            headerName: 'Due', columnGroupShow: 'open', field: 'dueDate', maxWidth: 126, minWidth: 126,
          },
          {
            headerName: 'Liquidation', columnGroupShow: 'open', field: 'liquidationDate', maxWidth: 126, minWidth: 126,
          },
        ],
      },
      {
        headerName: 'Fees',
        marryChildren: true,
        children: [
          {
            headerName: 'Lines', field: 'lineCount', maxWidth: 90, minWidth: 90, enableRowGroup: false, enablePivot: false,
          },
          {
            headerName: 'Grand Total', field: 'grandTotal', maxWidth: 145, minWidth: 145, enableRowGroup: false, enablePivot: false,
          },
          {
            headerName: 'Duty', columnGroupShow: 'open', field: 'duty', maxWidth: 100, minWidth: 80, enableRowGroup: false, enablePivot: false,
          },
          {
            headerName: 'MPF', columnGroupShow: 'open', field: 'mpf', maxWidth: 100, minWidth: 80, enableRowGroup: false, enablePivot: false,
          },
          {
            headerName: 'HMF', columnGroupShow: 'open', field: 'hmf', maxWidth: 100, minWidth: 80, enableRowGroup: false, enablePivot: false,
          },
        ],
      },
      {
        headerName: 'Edit Log',
        marryChildren: true,
        children: [
          {
            headerName: 'Updated By', field: 'lastUpdatedBy', maxWidth: 134, minWidth: 100,
          },
          {
            headerName: 'Updated On',
            field: 'lastUpdatedOn',
            maxWidth: 158,
            minWidth: 100,
            enableRowGroup: false,
            enablePivot: false,
          },
          {
            headerName: 'Added By', columnGroupShow: 'open', field: 'addedBy', maxWidth: 134, minWidth: 100,
          },
          {
            headerName: 'Added On',
            columnGroupShow: 'open',
            field: 'addedDate',
            maxWidth: 158,
            minWidth: 100,
            enableRowGroup: false,
            enablePivot: false,
          },
        ],
      },
    ]
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.ESGrid.saveState('ES') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.ESGrid.saveState('ES') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.ESGrid.saveState('ES') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.ESGrid.saveState('ES') }
    this.aggrid.frameworkComponents = {
      ViewBtnRenderer,
    }
    this.LoadAll()
  },
  methods: {
    Open(esId) {
      this.esId = esId
      this.$router.push(`/abi/es/detail/${esId}`)
    },
    DoubleClickOpenPopup(params) {
      this.esId = params.data.esId
      this.$router.push(`/abi/es/detail/${params.data.esId}`)
    },
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadES(), this.LoadCustomerList(), this.LoadCountryList()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadES() {
      axios.get('/entry/list')
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.ESGrid.ResetColumns()
        })
    },
    LoadCustomerList() {
      // Loading Customer List
      return axios.get('/abi/customer/codes', {
        params: {
          UserId: this.$store.state.user.AppActiveUser.Id,
          DBContext: (this.$store.state.user.AppActiveUser.ABIDbContext),
        },
      })
        .then(rowData => {
          this.CustomerOptions = rowData.data
        })
    },
    LoadCountryList() {
      // Loading Country List
      return axios.get('/abi/codes/country')
        .then(rowData => {
          this.CountryOptions = rowData.data
        })
    },
    CreateNewES() {
      Object.assign(this.$data.header, this.$options.data.call(this))

      this.$refs.CreateEntry.show()
    },
    SaveNewEntry() {
      this.$refs.headerRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          const ESHeaderModel = {
            CustomerId: this.header.CustomerId.id,
            EntryType: this.header.EntryType.value,
            MOT: this.header.MOTType.value,
            ShipmentType: this.header.ShipmentType.value,
            ReferenceNumber: this.header.ReferenceNumber,
            BrokerReference: this.header.BrokerReference,
            Description: this.header.Description,
            COO: this.header.COO.countryCode,
            ExportCountry: this.header.ExportCountry.countryCode,
          }

          axios.post('/entry/create', ESHeaderModel)
            .then(response => {
              this.LoadingEnd()
              this.$refs.CreateEntry.hide()
              this.DisplaySuccess('Entry data has been saved.').then(() => {
                this.$router.push(`/abi/es/detail/${response.data}`)
              })
            })
            .catch(e => {
              this.LoadingEnd()
              this.$refs.CreateEntry.hide()
              this.DisplayError('New Entry cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('New Entry cannot be saved', 'Please check data requirements.')
        }
      })
    },
  },
}
</script>
