var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('AgGrid',{ref:"ESGrid",attrs:{"grid":_vm.aggrid},on:{"LoadAll":_vm.LoadAll},scopedSlots:_vm._u([{key:"lftbtn",fn:function(){return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Create a New entry'),expression:"'Create a New entry'",modifiers:{"hover":true}},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.CreateNewES()}}},[_c('font-awesome-icon',{staticClass:"mr-50",attrs:{"icon":['far', 'plus-square'],"size":"lg"}}),_c('span',{staticClass:"align-middle"},[_vm._v("New Entry")])],1)]},proxy:true}])})],1),_c('b-modal',{ref:"CreateEntry",attrs:{"id":"CreateEntryModal","title":"New Entry","size":"lg","centered":"","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.SaveNewEntry()}}},[_c('span',{staticClass:"align-middle"},[_vm._v("Save")])])],1)]},proxy:true}])},[_c('validation-observer',{ref:"headerRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Customer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Customer:")])])]),_c('v-select',{attrs:{"id":"CustomerOption","options":_vm.CustomerOptions,"label":"name","placeholder":"Select a Customer"},model:{value:(_vm.header.CustomerId),callback:function ($$v) {_vm.$set(_vm.header, "CustomerId", $$v)},expression:"header.CustomerId"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Entry Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Entry Type:")])])]),_c('v-select',{attrs:{"id":"EntryTypeOption","options":_vm.EntryTypeOptions,"label":"text","placeholder":"Select the entry type"},model:{value:(_vm.header.EntryType),callback:function ($$v) {_vm.$set(_vm.header, "EntryType", $$v)},expression:"header.EntryType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Shipment Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Shipment Type:")])])]),_c('v-select',{attrs:{"id":"ShipmentTypeOption","options":_vm.ShipmentTypeOptions,"label":"text","placeholder":"Select the shipment type"},model:{value:(_vm.header.ShipmentType),callback:function ($$v) {_vm.$set(_vm.header, "ShipmentType", $$v)},expression:"header.ShipmentType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"MOT","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("MOT:")])])]),_c('v-select',{attrs:{"id":"MOTOption","options":_vm.MOTOptions,"label":"text","placeholder":"Select the MOT"},model:{value:(_vm.header.MOTType),callback:function ($$v) {_vm.$set(_vm.header, "MOTType", $$v)},expression:"header.MOTType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Reference #","rules":"max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Reference #:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"ReferenceNumber","state":errors.length > 0 ? false:null,"placeholder":"Reference Number"},model:{value:(_vm.header.ReferenceNumber),callback:function ($$v) {_vm.$set(_vm.header, "ReferenceNumber", $$v)},expression:"header.ReferenceNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Broker Reference","rules":"max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('i',[_c('b',[_vm._v("Broker Reference:")])])]),_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"BrokerReference","state":errors.length > 0 ? false:null,"placeholder":"Broker Reference"},model:{value:(_vm.header.BrokerReference),callback:function ($$v) {_vm.$set(_vm.header, "BrokerReference", $$v)},expression:"header.BrokerReference"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Goods Description","rules":"required|max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Goods Description:")])])]),_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"Description","state":errors.length > 0 ? false:null,"placeholder":"Goods Description"},model:{value:(_vm.header.Description),callback:function ($$v) {_vm.$set(_vm.header, "Description", $$v)},expression:"header.Description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Country of Origin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Country of Origin:")])])]),_c('v-select',{attrs:{"id":"COO","options":_vm.CountryOptions,"label":"country","placeholder":"Select the COO"},model:{value:(_vm.header.COO),callback:function ($$v) {_vm.$set(_vm.header, "COO", $$v)},expression:"header.COO"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Export Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Export Country:")])])]),_c('v-select',{attrs:{"id":"ExportCountry","options":_vm.CountryOptions,"label":"country","placeholder":"Select the export country"},model:{value:(_vm.header.ExportCountry),callback:function ($$v) {_vm.$set(_vm.header, "ExportCountry", $$v)},expression:"header.ExportCountry"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }